<template>
<OModal ref="checklistProcedureModal" name="checklistProcedureModal"> 
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ procedures.length > 1 ? $t('Checklist Procedures') : $t('Checklist Procedure') }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <template v-for="p in procedures" :key="p.ChecklistName">
                    <h5>{{ $t("Checklist") }}: {{ p.ChecklistName }}</h5>
                    <p v-html="escapeHTML(p.ChecklistProcedure)"></p>
                </template>                
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("Close") }}</button>
            </div>
        </div>
    </div>
</OModal>
</template>

<script setup>
import { ref, defineExpose } from 'vue';

const checklistProcedureModal = ref(null);
const procedures = ref([]);

function show(proceduresData) {
    procedures.value = proceduresData;
    checklistProcedureModal.value.show();
}

function escapeHTML(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.innerHTML;
}

defineExpose ({ show });
</script>